import './bootstrap';
import '@fontsource-variable/inter';
import '@fontsource-variable/material-symbols-rounded';
import '../css/app.css';
import '@/pusher-echo-config.js';


import Swal from 'sweetalert2';

window.Swal = Swal;

const popups = document.getElementsByClassName("popup_btn");

const loadToastrCSS = () => {
    if (document.querySelector('link[href="https://cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/css/toastr.min.css"]')) return;
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "https://cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/css/toastr.min.css";
    document.head.appendChild(link);
};

for (const popup of popups) {
    popup.addEventListener("focus", (e) => {
        e.target.setAttribute("aria-expanded", "true");
    });

    popup.addEventListener("blur", (e) => {
        e.target.setAttribute("aria-expanded", "false");
    });
}

document.addEventListener("DOMContentLoaded", loadToastrCSS);


console.log('app.js loaded');

window.addEventListener('DOMContentLoaded', (event) => {
    window.Echo.channel('session')
        .listen('.ping', (e) => {
            console.log(e);
        });
});
